@import "src/styles/colors.scss"; @import "src/styles/mixins.scss"; @import "src/styles/fonts.scss";
.wrapper {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  font-family: 'Avenir';
  border-radius: 12px;
  max-width: 1080px;
  gap: 35px;
  margin: auto;
  margin-top: 35px;

  &.isEmpty {
    display: none;
  }
}